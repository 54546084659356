var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHome),expression:"showHome"}],attrs:{"id":"loginbox"}},[_c('img',{attrs:{"src":require("@/assets/img/Special/post.png")}}),_c('van-form',{ref:"form",staticClass:"lform",on:{"submit":_vm.submit}},[_c('van-field',{staticClass:"lform-input phone",attrs:{"placeholder":"请输入手机号","name":"phone","rules":[
          {
            required: true,
            validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
            message: '请填写正确的手机号'
          }
        ]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{staticClass:"lform-input yzm",attrs:{"placeholder":"请输入验证码","rules":[
          {
            required: true,
            message: '请输入验证码'
          }
        ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [(!_vm.time)?_c('div',{staticClass:"cdown",on:{"click":_vm.sendCode}},[_vm._v("获取验证码")]):_c('van-count-down',{staticClass:"cdown",attrs:{"time":_vm.time},on:{"finish":function($event){_vm.time = 0}}})]},proxy:true}]),model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('van-button',{staticClass:"submit",attrs:{"loading":_vm.subLoading,"native-type":"submit","loading-text":"兑换中..."}},[_vm._v(" 去兑换 ")])],1),_vm._m(0)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lform explain"},[_c('div',{staticClass:"tips"},[_vm._v("兑换说明")]),_c('div',[_vm._v(" 1、首次获取券码需要输入手机号码验证码进行校验，第二次进入则无需再验证；"),_c('br'),_vm._v(" 2、请在有效期内使用，过期将无法使用，不退换不补偿；"),_c('br'),_vm._v(" 3、若兑换后显示库存不足，请重新兑换或联系客服，客服电 话： "),_c('a',{attrs:{"href":"tel:4006627299"}},[_vm._v("4006627299")]),_vm._v("； ")])])
}]

export { render, staticRenderFns }