<template>
  <div>
    <div id="loginbox" v-show="showHome">
      <img src="@/assets/img/Special/post.png" />

      <van-form class="lform" ref="form" @submit="submit">
        <!-- <van-field
          class="lform-input key"
          placeholder="请输入兑换码"
          v-model="form.redeCodeNo"
          :rules="[
            {
              required: true,
              message: '请输入兑换码'
            }
          ]"
        /> -->
        <van-field
          class="lform-input phone"
          placeholder="请输入手机号"
          v-model="form.phone"
          name="phone"
          :rules="[
            {
              required: true,
              validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
              message: '请填写正确的手机号'
            }
          ]"
        />
        <van-field
          class="lform-input yzm"
          placeholder="请输入验证码"
          v-model="form.code"
          :rules="[
            {
              required: true,
              message: '请输入验证码'
            }
          ]"
        >
          <template #button>
            <div class="cdown" v-if="!time" @click="sendCode">获取验证码</div>
            <van-count-down
              class="cdown"
              v-else
              :time="time"
              @finish="time = 0"
            />
          </template>
        </van-field>
        <van-button
          class="submit"
          :loading="subLoading"
          native-type="submit"
          loading-text="兑换中..."
        >
          去兑换
        </van-button>
      </van-form>
      <div class="lform explain">
        <div class="tips">兑换说明</div>
        <div>
          1、首次获取券码需要输入手机号码验证码进行校验，第二次进入则无需再验证；<br />
          2、请在有效期内使用，过期将无法使用，不退换不补偿；<br />
          3、若兑换后显示库存不足，请重新兑换或联系客服，客服电 话：
          <a href="tel:4006627299">4006627299</a>；
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { syncVerify, sendCode, checkForSyncLink } from '@/api/SyncLink.js'
export default {
  data() {
    return {
      time: 0,
      showHome: false,
      subLoading: false,
      form: {
        redeCodeNo: undefined,
        phone: undefined,
        code: null
      }
    }
  },
  mounted() {
    const { code: redeCodeNoPwd } = this.$route.params
    this.loadData({
      redeemCodePageType: 6,
      redeCodeNoPwd,
      pageTypeMatchFlag: true
    })
  },
  methods: {
    async loadData(param) {
      try {
        const { data } = await checkForSyncLink(param)
        this.form.redeCodeNo = data?.redeCodeNo
        if (data?.token) {
          this.$router.replace({
            name: 'SyncLinkList',
            query: data
          })
        } else {
          this.showHome = true
        }
      } catch (error) {
        this.showHome = true
        console.log(error)
      }
    },
    async submit() {
      try {
        this.subLoading = true
        const { code, data, message } = await syncVerify({
          ...this.form,
          redeCodeType: 8, // 兑换码类型，默认传6
          redeemCodePageType: 6
        })
        this.subLoading = false
        switch (code) {
          case 0:
            this.$router.replace({
              path: '/SyncLinkList',
              query: data
            })
            break
          case 90004:
            this.$router.push({
              path: '/SyncLinkOutStock'
            })
            break
          case 3:
            this.$router.push({
              path: '/SyncLinkCrowded'
            })
            break
          default:
            this.$toast.fail({
              message: message
            })
            break
        }
      } catch (error) {
        this.subLoading = false
      }
    },
    sendCode() {
      this.$refs.form.validate('phone').then(async() => {
        const { phone, redeCodeNo } = this.form
        const { code, message } = await sendCode({
          redeCodeNo,
          redeCodeType: 8, // 兑换码类型，默认传6
          phone
        })
        if (code === 0) {
          this.time = 60000
        } else {
          this.$toast.fail({
            message: message
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/Special.less';
</style>
